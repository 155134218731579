import React from "react";
import ImageFront from "../components/ImageFront";
import Wrapper from "../components/wrapper";
import team from "../images/team.jpg";
import EquipeContent from "../components/equipeContent";
import Layout from "../components/layout";

const Equipe = () => (
    <Layout>
        <ImageFront background={team} opacity="none" />
        <Wrapper>
            <h1>L'équipe</h1>
            <EquipeContent />
        </Wrapper>
    </Layout>
);

export default Equipe;
