import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import variable from "./variable";
import In from "../images/linkedin.svg";
import teamData from "../utils/equipeContent";

const ContentEquipe = () => (
    <React.Fragment>
        <div className="title-container">
            <h2 className="title-before">Une équipe qui a les nerfs</h2>
        </div>
        <Team>
            {teamData.map(teamItem => {
                return (
                    <TeamUnit key={teamItem.name}>
                        <img
                            src={teamItem.photo}
                            alt="team-member"
                            className="photographie"
                            onMouseOver={e => {
                                e.currentTarget.src = teamItem.photoNerf;
                            }}
                            onMouseOut={e => {
                                e.currentTarget.src = teamItem.photo;
                            }}
                        />
                        <div className="rank">{teamItem.rank}</div>
                        <div className="description">
                            <h4 className="mb0">{teamItem.name}</h4>
                            <p>{teamItem.description}</p>
                            <a href={teamItem.linkedin} target="_blank" rel="noopener noreferrer">
                                {teamItem.linkedin && <img src={In} alt="Gerald deshayes" className="In" />}
                            </a>
                        </div>
                        <div />
                        {teamItem.candidat && <Link to="Contact" className="CandidateUnit" />}
                    </TeamUnit>
                );
            })}
        </Team>
    </React.Fragment>
);

const Team = styled.div`
    display: grid;
    display: -ms-grid;
    grid-gap: 20px 15px;
    -ms-grid-columns: auto 15px auto 15px auto 15px auto;
    grid-template-columns: auto auto auto auto;
    margin-bottom: 40px;
    @media screen and (max-width: 992px) {
        grid-template-columns: auto auto;
        -ms-grid-columns: auto 15px auto;
    }
    @media screen and (max-width: 600px) {
        grid-template-columns: auto;
        -ms-grid-columns: auto;
    }
`;
const TeamUnit = styled.div`
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    .photographie {
        width: 100%;
        object-fit: cover;
        max-height: 450px;
    }
    .rank {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background-color: ${variable.secondaryColor};
        padding: 5px 15px 5px 15px;
        font-weight: 500;
        color: #fff;
        height: 30px;
        width: 65%;
        min-width: 180px;
        margin: -15px auto 0;
    }
    .CandidateUnit {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    .description {
        min-height: 160px;
        text-align: center;
        margin-top: -15px;
        padding: 20px;
        border-radius: 0 0 15px 15px;
        border: 2px solid ${variable.lightGrey};
        border-top: none;
        color: ${variable.darkGrey};
        h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .In {
            width: 20px;
        }
        p {
            font-weight: 500;
            margin-top: 10px;
            color: #cccccc;
        }
    }
`;
export default ContentEquipe;
