import gerald from "../images/photo-equipe/gerald.jpg";
import geraldNerf from "../images/photo-equipe/gerald-nerf.jpg";
import jacqueline from "../images/photo-equipe/jacqueline.jpg";
import jacquelineNerf from "../images/photo-equipe/jacqueline-nerf.jpg";
import ludovic from "../images/photo-equipe/ludovic.jpg";
import ludovicNerf from "../images/photo-equipe/ludovic-nerf.jpg";
import rabie from "../images/photo-equipe/rabie.jpg";
import rabieNerf from "../images/photo-equipe/rabie-nerf.jpg";
import olivier from "../images/photo-equipe/olivier.jpg";
import olivierNerf from "../images/photo-equipe/olivier-nerf.jpg";
import pierre from "../images/photo-equipe/pierre.jpg";
import pierreNerf from "../images/photo-equipe/pierre-nerf.jpg";
import eliott from "../images/photo-equipe/eliott.jpg";
import eliottNerf from "../images/photo-equipe/eliott-nerf.jpg";
import john from "../images/photo-equipe/john.jpg";
import johnNerf from "../images/photo-equipe/john-nerf.jpg";
import nicolasc from "../images/photo-equipe/nicolasc.jpg";
import nicolascNerf from "../images/photo-equipe/nicolasc-nerf.jpg";
import damien from "../images/photo-equipe/damien.jpg";
import damienNerf from "../images/photo-equipe/damien-nerf.jpg";
import quentin from "../images/photo-equipe/quentin.jpg";
import quentinNerf from "../images/photo-equipe/quentin-nerf.jpg";
import david from "../images/photo-equipe/david.jpg";
import davidNerf from "../images/photo-equipe/david-nerf.jpg";
import valentind from "../images/photo-equipe/valentind.jpg";
import valentindNerf from "../images/photo-equipe/valentind-nerf.jpg";
import florent from "../images/photo-equipe/florent.jpg";
import florentNerf from "../images/photo-equipe/florent-nerf.jpg";
import rodolphe from "../images/photo-equipe/rodolphe.jpg";
import rodolpheNerf from "../images/photo-equipe/rodolphe-nerf.jpg";
import valentinc from "../images/photo-equipe/valentinc.jpg";
import valentincNerf from "../images/photo-equipe/valentinc-nerf.jpg";
import vincent from "../images/photo-equipe/vincent.jpg";
import vincentNerf from "../images/photo-equipe/vincent-nerf.jpg";
import nicolasl from "../images/photo-equipe/nicolasl.jpg";
import nicolaslNerf from "../images/photo-equipe/nicolasl-nerf.jpg";
import amandine from "../images/photo-equipe/amandine.jpg";
import amandineNerf from "../images/photo-equipe/amandine-nerf.jpg";
import laurent from "../images/photo-equipe/laurent.jpg";
import laurentNerf from "../images/photo-equipe/laurent-nerf.jpg";
import mahefa from "../images/photo-equipe/mahefa.jpg";
import mahefaNerf from "../images/photo-equipe/mahefa-nerf.jpg";
import fond from "../images/photo-equipe/fond.jpg";
import random2 from "../images/photo-equipe/random2.jpg";

const teamData = [
    {
        name: "Gerald DESHAYES",
        photo: gerald,
        photoNerf: geraldNerf,
        rank: "CTO",
        description: "La brute",
        linkedin: "https://www.linkedin.com/in/gerald-deshayes-12624280/",
    },
    {
        name: "Jacqueline DESPREUX",
        photo: jacqueline,
        photoNerf: jacquelineNerf,
        rank: "Admin",
        description: "Môman Jacquotte",
        linkedin: "https://fr.linkedin.com/company/docapostagility",
    },
    {
        name: "Ludovic DELAHAYE",
        photo: ludovic,
        photoNerf: ludovicNerf,
        rank: "Lead Squad Dev Back",
        description: "Agilitateur",
        linkedin: "https://www.linkedin.com/in/ludovic-delahaye-110a7094/",
    },
    {
        name: "Rabie ELKADDARI",
        photo: rabie,
        photoNerf: rabieNerf,
        rank: "Lead Squad Dev Back",
        description: "Agitateur",
        linkedin: "https://fr.linkedin.com/company/docapostagility",
    },
    {
        name: "Olivier LEFRANCOIS",
        photo: olivier,
        photoNerf: olivierNerf,
        rank: "Lead Squad Dev Back",
        description: "Le Pompier",
        linkedin: "https://www.linkedin.com/in/olivier-lefran%C3%A7ois-02841ab7/",
    },
    {
        name: "Pierre FITTE-DUVAL",
        photo: pierre,
        photoNerf: pierreNerf,
        rank: "Lead Squad Test",
        description: "Le Soleil",
        linkedin: "https://fr.linkedin.com/company/docapostagility",
    },
    {
        name: "Eliott GOYE",
        photo: eliott,
        photoNerf: eliottNerf,
        rank: "Archi Tech",
        description: "E.T.",
        linkedin: "https://www.linkedin.com/in/eliottgoye",
    },
    {
        name: "Jonathan CLERY",
        photo: john,
        photoNerf: johnNerf,
        rank: "Testeur",
        description: "Toujours plus",
        linkedin: "https://www.linkedin.com/in/jonathan-clery-70018713b/",
    },
    {
        name: "Nicolas CHAURIS",
        photo: nicolasc,
        photoNerf: nicolascNerf,
        rank: "Ingé Dev Front",
        description: "Mini Fit",
        linkedin: "https://fr.linkedin.com/company/docapostagility",
    },
    {
        name: "Damien FITTE-DUVAL",
        photo: damien,
        photoNerf: damienNerf,
        rank: "Ingé Dev",
        description: "9h30",
        linkedin: "https://fr.linkedin.com/company/docapostagility",
    },
    {
        name: "Quentin AUVRAY",
        photo: quentin,
        photoNerf: quentinNerf,
        rank: "Ingé Dev Back",
        description: "Highlander",
        linkedin: "https://fr.linkedin.com/company/docapostagility",
    },
    {
        name: "David DADA",
        photo: david,
        photoNerf: davidNerf,
        rank: "Ingé Dev Front",
        description: "Le perfectionniste",
        linkedin: "https://www.linkedin.com/in/david-dada-9b715ba6/",
    },
    {
        name: "Valentin DEFRANCE",
        photo: valentind,
        photoNerf: valentindNerf,
        rank: "Ingé Dev Front",
        description: "RageQuit",
        linkedin: "https://www.linkedin.com/in/valentin-defrance-0233b560",
    },
    {
        name: "Florent LESEUR",
        photo: florent,
        photoNerf: florentNerf,
        rank: "Ingé Dev Front",
        description: "The Bearded One",
        linkedin: "https://www.linkedin.com/in/florent-leseur-b8545699/",
    },
    {
        name: "Rodolphe LAINE",
        photo: rodolphe,
        photoNerf: rodolpheNerf,
        rank: "Ingé Dev Front",
        description: "Gus",
        linkedin: "https://www.linkedin.com/in/rodolphe-laine-a487b3a9/",
    },
    {
        name: "Valentin CROCHEMORE",
        photo: valentinc,
        photoNerf: valentincNerf,
        rank: "Ingé Dev Back",
        description: "Clark Kent",
        linkedin: "https://www.linkedin.com/in/valentin-crochemore-74442ba8/",
    },
    {
        name: "Vincent HIRTZ",
        photo: vincent,
        photoNerf: vincentNerf,
        rank: "Ingé Dev Front",
        description: "ReD",
        linkedin: "https://www.linkedin.com/in/hirtzvincent/",
    },
    {
        name: "Nicolas LEVESQUE",
        photo: nicolasl,
        photoNerf: nicolaslNerf,
        rank: "Ingé Dev Back",
        description: "Spinning ceramic",
        linkedin: "https://fr.linkedin.com/company/docapostagility",
    },
    {
        name: "Amandine BELIEN",
        photo: amandine,
        photoNerf: amandineNerf,
        rank: "Ingé Dev Back",
        description: "Choubi",
        linkedin: "https://www.linkedin.com/in/amandine-belien-07981a56 /",
    },
    {
        name: "Laurent LANGLAIS",
        photo: laurent,
        photoNerf: laurentNerf,
        rank: "Ingé Dev Front",
        description: "L’unique",
        linkedin: "https://www.linkedin.com/in/laurent-langlais-69637a9a/",
    },
    {
        name: "Mahefa RAKOTONDRATSIMA",
        photo: mahefa,
        photoNerf: mahefaNerf,
        rank: "Archi Tech",
        description: "Admin parano",
        linkedin: "https://www.linkedin.com/in/mahefa-rakotondratsima-48764aa8/",
    },

    {
        name: "Mickeal Caron",
        photo: random2,
        photoNerf: random2,
        rank: "Ingé Dev Back",
        description: "L'ancien",
        linkedin: "https://www.linkedin.com/in/mahefa-rakotondratsima-48764aa8/",
    },
    {
        name: "Maxime Clément",
        photo: random2,
        photoNerf: random2,
        rank: "Dev Front/UX",
        description: "Le gamin",
        linkedin: "https://www.linkedin.com/in/mahefa-rakotondratsima-48764aa8/",
    },
    {
        name: "Vous ?",
        photo: fond,
        photoNerf: fond,
        rank: "",
        description: "",
        linkedin: "",
        candidat: true,
    },
];

export default teamData;
